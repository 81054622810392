<template>
  <div class="risk-point-query">
    <div
      v-for="(item, idx) in configs"
      :key="idx"
      class="risk-point-query__item"
      @click="onSearchClick(item, idx)"
    >
      <template v-if="item.show">
        <span class="risk-point-query__item-title">{{ item.title }}</span>
        <van-icon
          name="zhankai"
          class-prefix="iconfont"
          class="risk-point-query__item-icon"
        />
      </template>
    </div>
    <select-popup
      :choose-value="popupValue"
      :show-filter="popupFilter"
      :data="popupData"
      :title="popupTitle"
      :visible="popupVisible"
      :popup-style="{
        height: '50vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
  </div>
</template>

<script>
import SelectPopup from "@/components/SelectPopup";
import {
  getAreaSelect,
  getLevelSelect,
  getSelectRiskPointType
} from "@/api/psm/risk";
import projectTextConfig from "@/utils/projectTextConfig";

export default {
  name: "RiskPointQuery",
  components: { SelectPopup },
  props: ["query"],
  data() {
    return {
      queryInfo: {
        riskAreaId: "",
        pointType: "",
        controlRiskLevel: ""
      },
      configs: [
        {
          title: projectTextConfig.TEXT_RISK_POINT_AREA,
          type: "riskAreaId",
          types: "selectRiskAreaId",
          show: true
        },
        {
          title: "类型",
          type: "pointType",
          types: "selectPointType",
          show: this.isRt
        },
        {
          title: "等级",
          type: "controlRiskLevel",
          types: "selectRiskLevel",
          show: true
        }
      ],
      popupVisible: false,
      popupTitle: "",
      popupData: [],
      selectRiskAreaId: [],
      selectPointType: [],
      selectRiskLevel: [],
      selectValue: "",
      popupValue: "",
      popupType: "",
      popupFilter: false
    };
  },
  created() {
    this.initTypes();
  },
  methods: {
    refreshQuery() {
      this.$emit("refresh", this.queryInfo);
    },
    async initTypes() {
      try {
        const ret = await Promise.all([
          getAreaSelect(),
          getSelectRiskPointType(),
          getLevelSelect()
        ]);
        this.selectRiskAreaId = ret[0];
        this.selectPointType = ret[1];
        this.selectRiskLevel = ret[2];
      } catch (error) {
        console.log("initTypes -> error", error);
      }
    },
    onSearchClick({ title, type, types }, idx) {
      console.log(title, type, types, idx);
      this.popupValue = this.queryInfo[type] || "";
      this.popupType = type;
      this.popupFilter = type === "riskAreaId";
      this.popupData = this[types];
      this.popupTitle = title;
      this.popupVisible = true;
    },
    onPopupReset() {
      this.queryInfo[this.popupType] = "";
      this.popupValue = "";
      this.refreshQuery();
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    onPopupConfirm(row) {
      this.queryInfo[this.popupType] = row.value;
      this.refreshQuery();
    }
  }
};
</script>

<style lang="scss">
.risk-point-query {
  display: flex;
  justify-content: space-between;
  padding: 12px 46px;
  margin-top: -2px;
  color: #3b4664;
  font-size: 14px;
  border-bottom: 1px solid #c7c9d0;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-icon {
      margin-left: 4px;
      color: #aeb3c0;
      font-size: 12px;
    }
  }
}
</style>
