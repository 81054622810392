<template>
  <div class="risk-point">
    <risk-header
      :current="1"
      :btn-auth="btnAuth.add"
      @change="onTabChange"
      @back="$router.push('/')"
      @add="onAdd"
    ></risk-header>
    <div>
      <van-search
        v-model="queryInfo.fuzzyName"
        :placeholder="`请输入${TEXT_RISK_POINT_SEARCH_NAME}`"
        class="search-bar"
        @search="onSearch"
        @clear="onRefresh"
      >
        <template #left-icon>
          <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
        </template>
      </van-search>
      <div class="search-query">
        <risk-point-query :query="queryInfo" @refresh="onQueryChange" />
        <div class="search-query__total">
          共{{ total }}个{{ TEXT_RISK_POINT_TOTAL_NAME }}
        </div>
      </div>
    </div>
    <van-pull-refresh
      ref="vanList"
      v-model="isPullRefreshing"
      class="risk-point-list"
      @refresh="onRefresh"
    >
      <van-list
        v-model="isLoading"
        :finished="isFinished"
        :finished-text="isError ? '' : '没有更多了'"
        :error.sync="isError"
        error-text="加载失败，请稍后再试！"
        @load="getList"
      >
        <div
          v-for="(item, idx) in list"
          :key="idx"
          :class="[
            'risk-point-list__rows',
            idx === list.length - 1 && 'border-none'
          ]"
          @click="gotoDetails(item)"
        >
          <van-swipe-cell
            :right-width="btnAuth.delete ? 70 : 0"
            :border="false"
            :before-close="beforeClose"
          >
            <template v-if="btnAuth.delete" #right>
              <span
                class="delete-btn iconfont iconfont-shanchutubiao1"
                @click="deleteId = item.id"
              ></span>
            </template>
            <h3 class="title">{{ item.name }}</h3>
            <div class="tags">
              <div class="field">
                <van-icon
                  class-prefix="iconfont"
                  class="list-icon"
                  name="quyu1"
                />
                <span>{{ item.areaName }}</span>
              </div>
              <div v-if="isRt" class="field">
                <van-icon
                  class-prefix="iconfont"
                  class="list-icon"
                  name="fengxiandianleixing"
                />
                <span>{{ item.pointTypeName }}</span>
              </div>
              <span
                class="level"
                :style="{
                  color: levelMap[item.controlRiskLevel].color,
                  borderColor: levelMap[item.controlRiskLevel].borderColor,
                  backgroundColor:
                    levelMap[item.controlRiskLevel].backgroundColor
                }"
                >{{ item.controlRiskLevelName }}</span
              >
            </div>
          </van-swipe-cell>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import RiskPointQuery from "@/views/risk/components/RiskPointQuery";
import { deleteRiskPointById, getPageRiskPoint } from "@/api/psm/risk";
import { operateDialog, operateMessage } from "@/utils";
import { mapMutations, mapState } from "vuex";
import RiskHeader from "@/views/risk/components/RiskHeader";
export default {
  name: "RiskPoint",
  components: { RiskHeader, RiskPointQuery },
  data() {
    return {
      queryInfo: {
        page: 1,
        fuzzyName: "",
        riskAreaId: "",
        pointType: "",
        controlRiskLevel: "",
        sort: "utime_desc"
      },
      deleteId: "",
      levelMap: {
        9: {
          color: "#3B4664",
          backgroundColor: "#fff",
          borderColor: "#727b94"
        },
        0: {
          color: "#3B4664",
          backgroundColor: "#fff",
          borderColor: "#727b94"
        },
        1: {
          color: "#F13030",
          backgroundColor: "#ffdddd",
          borderColor: "#f13030"
        },
        2: {
          color: "#FF6D1E",
          backgroundColor: "#ffe7db",
          borderColor: "#ff6d1e"
        },
        3: {
          color: "#EAC200",
          backgroundColor: "#fffbe9",
          borderColor: "#edc400"
        },
        4: {
          color: "#1580FF",
          backgroundColor: "#dcecff",
          borderColor: "#1580ff"
        }
      },
      total: 0,
      list: [],
      // 下拉刷新，上拉加载
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false
    };
  },
  created() {
    this.onSearch();
  },
  computed: {
    ...mapState({
      needRefreshList: state => state.risk.needRefreshList,
      btnAuth: state => state.menu.btnAuth
    })
  },
  methods: {
    ...mapMutations({
      setNeedRefreshList: "setNeedRefreshList"
    }),
    onTabChange(row) {
      console.log(row, "onTabChange");
      this.$router.replace({ name: row.routeName });
    },
    onAdd() {
      this.$router.push({ name: "RiskPointAdd" });
    },
    onQueryChange(query) {
      this.queryInfo = { ...this.queryInfo, ...query };
      this.onRefresh();
    },
    onRefresh() {
      this.queryInfo.page = 1;
      this.total = 0;
      this.list = [];
      this.getList();
    },
    onSearch() {
      this.isFinished = false;
      this.isLoading = true;
      this.queryInfo.page = 1;
      this.list = [];
      this.total = 0;
      this.getList();
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        const { list, total } = await getPageRiskPoint(this.queryInfo);
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        if (list && list.length) {
          this.list = this.list.concat(list);
          this.queryInfo.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    beforeClose({ position, instance }) {
      switch (position) {
        case "right":
          operateDialog({
            title: "",
            message: "确认删除风险点嘛？"
          }).then(async () => {
            try {
              const r = await deleteRiskPointById(this.deleteId);
              operateMessage(r);
              if (r) this.onRefresh();
              instance.close();
              this.deleteId = "";
            } catch (e) {
              console.log(e);
            }
          });
          break;
      }
    },
    gotoDetails(row) {
      this.$router.push({ name: "RiskPointAdd", query: { id: row.id } });
    },
    scrollTo() {
      this.scrollHeight = this.$refs.vanList.$el.scrollTop;
    }
  },
  activated() {
    console.log("activated -> 风险点");
    this.$nextTick(() => {
      if (this.$refs.vanList && this.$refs.vanList.$el) {
        this.$refs.vanList.$el.scrollTo(0, this.scrollHeight || 0);
      }
    });
    if (this.needRefreshList) {
      this.onSearch();
      this.setNeedRefreshList({ value: false });
    }
  },
  beforeRouteLeave(to, from, next) {
    this.scrollTo();
    next();
  }
};
</script>

<style lang="scss">
.risk-point {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .delete-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 70px;
    background-color: #f95f5f;
    color: #fff;
    font-size: 28px;
    text-align: center;
  }
  .search-query {
    &__total {
      margin: 10px 0;
      text-align: center;
      color: #8c8f97;
      font-size: 12px;
      line-height: 17px;
    }
  }
  &-list {
    overflow: auto;
    flex: 1;
    .border-none {
      border: none;
    }
    .promised-color {
      color: #8c8f97;
    }
    .un-promise-color {
      color: #ff6d1e;
    }
    &__rows {
      border-bottom: 8px solid #eceef2;
      padding-left: 13px;
      .title {
        padding-top: 13px;
        margin-bottom: 10px;
        width: 70vw;
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
      }
      .tags {
        padding-bottom: 13px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        line-height: 17px;
        color: #3b4664;
        flex-wrap: wrap;
        .field {
          display: inline-block;
          white-space: nowrap;
          margin-right: 20px;
          max-width: 90vw;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .iconfont {
          font-size: 15px;
          color: #aeb3c0;
          margin-right: 4px;
        }
        .level {
          border: 1px solid;
          padding: 0 5px;
          border-radius: 2px;
        }
      }
    }
  }
}
</style>
