var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"risk-point"},[_c('risk-header',{attrs:{"current":1,"btn-auth":_vm.btnAuth.add},on:{"change":_vm.onTabChange,"back":function($event){return _vm.$router.push('/')},"add":_vm.onAdd}}),_c('div',[_c('van-search',{staticClass:"search-bar",attrs:{"placeholder":("请输入" + _vm.TEXT_RISK_POINT_SEARCH_NAME)},on:{"search":_vm.onSearch,"clear":_vm.onRefresh},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('van-icon',{staticClass:"search-icon",attrs:{"name":"sousuo","class-prefix":"iconfont"}})]},proxy:true}]),model:{value:(_vm.queryInfo.fuzzyName),callback:function ($$v) {_vm.$set(_vm.queryInfo, "fuzzyName", $$v)},expression:"queryInfo.fuzzyName"}}),_c('div',{staticClass:"search-query"},[_c('risk-point-query',{attrs:{"query":_vm.queryInfo},on:{"refresh":_vm.onQueryChange}}),_c('div',{staticClass:"search-query__total"},[_vm._v(" 共"+_vm._s(_vm.total)+"个"+_vm._s(_vm.TEXT_RISK_POINT_TOTAL_NAME)+" ")])],1)],1),_c('van-pull-refresh',{ref:"vanList",staticClass:"risk-point-list",on:{"refresh":_vm.onRefresh},model:{value:(_vm.isPullRefreshing),callback:function ($$v) {_vm.isPullRefreshing=$$v},expression:"isPullRefreshing"}},[_c('van-list',{attrs:{"finished":_vm.isFinished,"finished-text":_vm.isError ? '' : '没有更多了',"error":_vm.isError,"error-text":"加载失败，请稍后再试！"},on:{"update:error":function($event){_vm.isError=$event},"load":_vm.getList},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},_vm._l((_vm.list),function(item,idx){return _c('div',{key:idx,class:[
          'risk-point-list__rows',
          idx === _vm.list.length - 1 && 'border-none'
        ],on:{"click":function($event){return _vm.gotoDetails(item)}}},[_c('van-swipe-cell',{attrs:{"right-width":_vm.btnAuth.delete ? 70 : 0,"border":false,"before-close":_vm.beforeClose},scopedSlots:_vm._u([(_vm.btnAuth.delete)?{key:"right",fn:function(){return [_c('span',{staticClass:"delete-btn iconfont iconfont-shanchutubiao1",on:{"click":function($event){_vm.deleteId = item.id}}})]},proxy:true}:null],null,true)},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"tags"},[_c('div',{staticClass:"field"},[_c('van-icon',{staticClass:"list-icon",attrs:{"class-prefix":"iconfont","name":"quyu1"}}),_c('span',[_vm._v(_vm._s(item.areaName))])],1),(_vm.isRt)?_c('div',{staticClass:"field"},[_c('van-icon',{staticClass:"list-icon",attrs:{"class-prefix":"iconfont","name":"fengxiandianleixing"}}),_c('span',[_vm._v(_vm._s(item.pointTypeName))])],1):_vm._e(),_c('span',{staticClass:"level",style:({
                color: _vm.levelMap[item.controlRiskLevel].color,
                borderColor: _vm.levelMap[item.controlRiskLevel].borderColor,
                backgroundColor:
                  _vm.levelMap[item.controlRiskLevel].backgroundColor
              })},[_vm._v(_vm._s(item.controlRiskLevelName))])])])],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }